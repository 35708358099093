import React, { useEffect, useState } from "react";

import { CardCripto } from "../../components";

import { Container, BoxCards, Title } from "./styles";

function Block02() {
  const [cryptos, setCryptos] = useState([]);

  useEffect(() => {
    fetch("/api/cryptos")
      .then(response => response.json())
      .then(responseData => {
        setCryptos(responseData);
      })
      .catch(error => console.warn(error));
  }, []);
  console.log(cryptos);
  return (
    <Container id="negocie">
      <Title>
        [negocie] <strong>[bitcoin]</strong>
      </Title>
      <BoxCards>
        <CardCripto
          img="/cripto1.svg"
          value="0.5"
          abbreviation="BKAI"
          name="Bank.ai"
          up={true}
          variation={false}
        />
        {cryptos?.map((crypto, key) => (
          <CardCripto
            key={crypto?.abbreviation}
            img={`/crypto-${crypto?.abbreviation}.svg`}
            value={crypto?.buy}
            abbreviation={crypto?.abbreviation}
            name={crypto?.name}
            variation={crypto?.variation}
          />
        ))}
      </BoxCards>
    </Container>
  );
}

export default Block02;
