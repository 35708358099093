import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 115px 12vw 101px;
	align-items: center;

	.decoration {
		position: absolute;
		left: 0px;
		z-index: 1;
	}

	.desktop {
		display: flex;
	}

	.mobile {
		display: none;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		margin: 0 2vw;

		.decoration {
			position: absolute;
			left: 0px;
			top: 3650px;
			width: 240px;
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;
		}

		.desktop {
			display: none;
		}

		.mobile {
			display: flex;
		}
	}
`;

export const BoxText = styled.div`
	max-width: 32vw;
	margin-right: 8vw;
	position: relative;
	z-index: 2;

	button {
		width: 210px;
	}

	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		margin: 0 6vw 40px;

		h1 {
			font-weight: 600;
			font-size: 24px;
			line-height: 29px;
		}

		button {
			margin-bottom: 39px;
		}
	}
`;
