import styled from "styled-components";

export const Container = styled.div`  
  margin: 0 6vw;
  padding-top: 80px;

  @media only screen and (max-width: 1200px) {
    margin: 0 2vw;
    padding-top: 0;
  }

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fafafa;
  }

  span {
    color: #7d30e0;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #FAFAFA;
  margin: 0;

  strong {
    color: #B794F6;
    font-weight: 600;
  }
`

export const BoxCards = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 80px 0 170px 0;
  gap: 28px;

  @media only screen and (max-width: 1200px) {
    margin: 35px 0 102px 0;
    overflow: scroll;
  }
`;
