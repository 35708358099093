import React from "react";

import { Container, BoxActions, Action, ActionImage, Title } from "./styles";

function Block05() {
  return (
    <Container>
      <Title>
        [liberdade] _para_ <strong>[comprar]</strong>
      </Title>
      <BoxActions>
        <Action>
          <ActionImage>
            <img
              src="/comprar.svg"
              width="19"
              height="16"
              loading="lazy"
              alt=""
            />
          </ActionImage>
          <span>Comprar</span>
        </Action>
        <Action>
          <ActionImage>
            <img
              src="/sacar.svg"
              width="19"
              height="16"
              loading="lazy"
              alt=""
            />
          </ActionImage>
          <span>Sacar</span>
        </Action>
        <Action>
          <ActionImage>
            <img
              src="/enviar.svg"
              width="18"
              height="15"
              loading="lazy"
              alt=""
            />
          </ActionImage>
          <span>Enviar</span>
        </Action>
        <Action>
          <ActionImage>
            <img
              src="/receber.svg"
              width="18"
              height="15"
              loading="lazy"
              alt=""
            />
          </ActionImage>
          <span>Receber</span>
        </Action>
        <Action>
          <ActionImage>
            <img
              src="/converter.svg"
              width="19"
              height="13"
              loading="lazy"
              alt=""
            />
          </ActionImage>
          <span>Converter</span>
        </Action>
        <Action>
          <ActionImage>
            <img
              src="/gastar.svg"
              width="19"
              height="16"
              loading="lazy"
              alt=""
            />
          </ActionImage>
          <span>Gastar</span>
        </Action>
      </BoxActions>
    </Container>
  );
}

export default Block05;
