import React, { useRef } from "react";

import { isMobile } from "react-device-detect";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

import { Card } from "../../components";
import {
  Container,
  BoxTitle,
  BoxCards,
  ContainerButtons,
  Button,
  BoxHeader,
} from "./styles";

import { Cartola, Title } from "../../components/Templates/texts";

function Block07() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Container id="vantagens">
      <BoxHeader>
        <img
          className="decoration"
          src="/block07-decoration.svg"
          width="518"
          height="288"
          alt=""
        />
        <img
          className="decorationMobile"
          src="/block07-decoration-mb.svg"
          width="120"
          height="117"
          alt=""
        />
      </BoxHeader>
      <BoxTitle>
        <Cartola>Vantagens</Cartola>
        <Title>
          Projetada para aqueles que <br />
          entendem o poder da promessa <br />
          das criptomoedas e querem <br />
          fazer parte do movimento
        </Title>
      </BoxTitle>
      <BoxCards>
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 1,
            },
            // when window width is >= 1024px
            1024: {
              width: 1024,
              slidesPerView: 1,
            },
            // when window width is >= 1200px
            1200: {
              width: 1200,
              slidesPerView: 3,
            },
          }}
          spaceBetween={50}
          slidesPerGroup={1}
          loop={true}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          className="mySwiper">
          <SwiperSlide>
            <Card
              src="/icon1.svg"
              title="Suporte 24/7"
              paragraph="Suas negociações não param, nós também não. Nossa equipe de especialistas está a postos para você"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              src="/icon2.svg"
              title="Proteção robusta"
              paragraph="Para garantir o cumprimento de práticas de administração de risco é utilizado a verificação KYC/KYT."
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              src="/icon3.svg"
              title="Velocidade"
              paragraph="Nosso mecanismo de transações garante agilidade e velocidade em todas as suas transações"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              src="/icon4.svg"
              title="Segurança de próximo nível"
              paragraph="Nossa plataforma foi construída com várias camadas de proteção, garantindo a segurança de suas criptomoedas."
            />
          </SwiperSlide>
          <ContainerButtons>
            <Button name="prev" aria-label="prev" ref={prevRef}>
              <img
                src="/prev-icon.svg"
                loading="lazy"
                height="14"
                width="14"
                alt=""
              />
            </Button>
            <Button name="next" aria-label="next" ref={nextRef}>
              <img
                src="/next-icon.svg"
                loading="lazy"
                height="14"
                width="14"
                alt=""
              />
            </Button>
          </ContainerButtons>
        </Swiper>
      </BoxCards>
    </Container>
  );
}

export default Block07;
