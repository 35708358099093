import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 6vw 85px;
  padding-top: 170px;

	.desktop {
		display: flex;
	}

	.mobile {
		display: none;
	}

	.decorationMobile {
		display: none;
	}

	@media only screen and (max-width: 1200px) {
		display: flex;
		flex-direction: row-reverse;
		flex-wrap: wrap;

		justify-content: space-between;
		margin: 52px 2vw;

		.decorationMobile {
			display: flex;
			position: relative;
			top: -300px;
			margin-bottom: -600px;
			z-index: 1;
		}

		.desktop {
			display: none;
		}

		.mobile {
			display: flex;
		}
	}
`;

export const BoxText = styled.div`
	max-width: 432px;
	margin-left: 60px;

	button {
		margin-top: 20px;
	}

	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		margin: 0 6vw;
		margin-top: 30px;

		h1 {
			font-weight: 600;
			font-size: 24px;
			line-height: 29px;
		}
	}
`;

export const BoxAdditional = styled.div`
	display: flex;

	img {
		margin-right: 12px;
	}
`;

export const BoxTitle = styled.div`
	max-width: 50vw;
	margin-bottom: 160px;

	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		margin: 0 6vw;

		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 36px;
		}
	}
`;

export const BoxHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.decoration {
		position: absolute;
		right: 0px;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		margin: 100px 0 0 0;

		.decoration {
			display: none;
		}
	}
`;

export const BoxContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 6vw;

	.decoration {
		position: relative;
		right: 0;
		top: 300px;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		margin: 43px 0 0 0;

		.decoration {
			display: none;
			position: relative;
			left: 150px;
			top: 0px;
		}
	}
`;
