import styled from "styled-components";
const arrow = "/select-arrow.png";

export const Container = styled.div`	
	width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 60px;
    left: 36%;
    width: 4px;
    height: 4px;
    background-color: #7E3FF2;
  }

	#contato {
		padding: 85px 6vw 103px;
		margin-top: 164px;
		justify-content: space-between;
		align-items: center;
		display: flex;
	}

	@media only screen and (max-width: 1200px) {
		display: flex;
		flex-direction: column;
		margin-top: 0;

		#contato {
			width: 100%;
			display: flex;
			margin-top: 0;
			padding: 37px 6vw;
			flex-direction: column;
		}
	}

  .detail {
    position: absolute;
    left: 42%;
    top: 140px;

    @media only screen and (max-width: 1200px) {
      top: 0;
      left: unset;
      right: 20px;
    }
  }
`;

export const BoxText = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 30%;
	margin-right: 100px;

	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		margin-right: 0;

		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 36px;
		}
	}
`;

export const BoxAdditional = styled.div`
	display: flex;
	align-items: center;

	img {
		margin-right: 12px;
	}
`;

export const ContainerButton = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;

	@media only screen and (max-width: 1200px) {
		button {
			width: 100%;
		}
	}
`;

export const BoxForm = styled.form`
	display: flex;
	flex-direction: column;
	min-width: 50%;

	select {
		font-family: Montserrat, sans-serif;
		color: #8D9BAD;
		background-color: #1e2c39;
		width: 100%;
		border: 1px solid #394B5C;
		height: 56px;
		padding: 18px 30px 20px 15px;
		transition: border-color 0.25s ease;
		font-weight: 500;
		font-size: 16px;
    border-radius: 3px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-image: url(${arrow});
		background-repeat: no-repeat;
		background-position-y: center;
		background-position-x: calc(100% - 20px);

		&:focus {
			border-color: #7d30e0;
			outline: none;
		}

		@media only screen and (max-width: 1200px) {
			margin-bottom: 10px;
		}
	}

	textarea {
		background-color: #1e2c39;
		width: 100%;
		color: #fafafa;
		height: 125px;
		font-family: Montserrat;
		font-weight: normal;
		border: 1px solid #324455;
		border-radius: 3px;
		padding: 15px 15px;
		resize: none;
		font-size: 16px;

    &::placeholder {
      color: #8D9BAD;
    }

		&:focus {
			border-color: #7d30e0;
			outline: none;
		}
	}

	@media only screen and (max-width: 1200px) {
		width: 100%;
		margin-top: 10px;

		select {
			width: 100%;
			margin-left: 0px;
		}
	}
`;

export const ContainerSelect = styled.div`
	display: flex;
	gap: 8px;

	div {
		width: 100%;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		gap: 0;
	}
`;
