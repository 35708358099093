const endpoint = "/api";

export const Api = {
  submitContact: function (data) {
    return Api.fetch("POST", "/contact", data);
  },

  fetch(method, path, data) {
    const args = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    if (data) {
      args.body = JSON.stringify(data);
    }

    return fetch(endpoint + path, args)
      .then(function (response) {
        if (response.status == 204) {
          return true;
        }

        return false;
      })
      .catch(function (error) {
        return Promise.resolve(false);
      });
  },
};
