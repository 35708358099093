import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	margin: 0 6vw;
  padding: 80px 0 0 0;
	align-items: center;
	justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -10vw;
    top: 0;
    width: 22vw;
    height: 22vw;
    max-width: 415px;
    max-height: 415px;
    border-radius: 435px;
    opacity: 0.2;
    background: #7E3FF2;
    filter: blur(77px);
    z-index: 1;
  }

	.desktop {
		display: flex;
	}

	.mobile {
		display: none;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		margin: 40px 2vw;
    padding: 0;

		.desktop {
			display: none;
		}

		.mobile {
			display: flex;
		}
	}
`;

export const BoxText = styled.div`
	max-width: 470px;
	margin-right: 8vw;
  position: relative;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    right: 30%;
    bottom: -70px;
    width: 4px;
    height: 4px;
    background-color: #7E3FF2;
  }

	button {
		width: 250px;
	}

	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		margin: 0 6vw 40px;

		button {
			width: 200px;
		}

		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 36px;
		}
	}

	button {
		margin-top: 16px;

		@media only screen and (max-width: 1200px) {
			margin-bottom: 51px;
		}
	}
`;
