import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	margin: 0 6vw;
  padding: 80px 0;
  gap: 118px;

	.desktop {
		display: flex;
	}

	.mobile {
		display: none;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
    gap: 52px;
		margin: 0 2vw 120px;
    padding: 0;

		.desktop {
			display: none;
		}

		.mobile {
			display: flex;
		}
	}
`;

export const BoxText = styled.div`
	max-width: 400px;

	button {
		margin-top: 20px;
	}

	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		margin: 52px 6vw 0;

		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 36px;
		}

		button {
			a {
				position: relative;
				z-index: 2;
			}
		}
	}
`;

export const BoxAdditional = styled.div`
	display: flex;
	flex-direction: column;

	div {
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		h4 {
			margin: 0;
		}

		img {
			margin-right: 12px;
		}
	}
`;
