import { Container, BoxTitle, BoxText, BoxContent, BoxHeader } from "./styles";

import { ButtonPattern } from "../../components";
import { Cartola, Title, Paragraph } from "../../components/Templates/texts";

function Block04() {
  return (
    <Container id="exchange">
      <img
        src="/block04-decoration-mb.svg"
        width="321"
        height="548"
        className="decorationMobile"
        loading="lazy"
        alt=""
      />

      <BoxHeader>
        <BoxTitle>
          <Cartola>Brazilian Exchange</Cartola>
          <Title>
            Muito além de investimento, negociação avançada com tecnologia de
            ponta
          </Title>
        </BoxTitle>
        <img
          src="/block04-decoration.svg"
          width="570"
          height="743"
          className="decoration"
          loading="lazy"
          alt=""
        />
      </BoxHeader>

      <BoxContent>
        <img
          src="/image-block4-min.png"
          className="desktop"
          width="600"
          height="600"
          loading="lazy"
          alt="Homem usando um tablet"
        />
        <img
          src="/image-block4-mobile-min.png"
          className="mobile"
          width="345"
          height="345"
          loading="lazy"
          alt="Homem usando um tablet"
        />
        <BoxText>
          <Cartola>Conta corrente</Cartola>
          <Title weight="600" size="32px" height="36px">
            Tenha acesso a conta auto-conversível. Compre e gaste em cripto.
          </Title>
          <Paragraph>
            Compre cripto e visualize seu saldo no padrão monetário que desejar.
            Uma das maiores vantagens de comprar na Bra.ex é poder ter acesso a
            uma conta autoconversível trazendo usabilidade cripto para o seu dia
            a dia.
          </Paragraph>
          <Paragraph>
            Desvende oportunidades de novas experiências de consumo no
            ecossistema de cripto.
          </Paragraph>
          <ButtonPattern anchor={"https://auth.2ndmarket.com.br/cadastro"}>
            Pré-cadastro
          </ButtonPattern>
        </BoxText>
        <img
          src="/block04-decoration-2.svg"
          width="178"
          height="127"
          className="decoration"
          loading="lazy"
          alt=""
        />
      </BoxContent>
    </Container>
  );
}

export default Block04;
