import { ButtonPattern } from "../../components";
import { BoxContent, Container } from "./styles";

function Page404() {
  return (
    <Container>
      <BoxContent>
        <img
          src="/vector/404.svg"
          width="658"
          height="300"
          alt="Imagem 404"
          loading="lazy"
          style={{ width: "100%", maxWidth: "658px"}}
        />
        <h1>Perdido?</h1>
        <p>Hum... parece que a página que você estava procurando não existe. Verifique a URL ou clique no botão abaixo para retornar a página inicial.</p>
        <ButtonPattern anchor="/">Voltar para o inicio</ButtonPattern>
      </BoxContent>
    </Container>
  );
}

export default Page404;
