import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 50px 0;

  @media only screen and (max-width: 1024px) {
    margin: 0;
    padding-bottom: 50px;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 50px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: #fafafa;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    color: #fafafa;
    margin: 0;
    width: 40%;
  }
  button {
    margin-top: 28px;
    width: 229px;
  }

  @media only screen and (max-width: 1025px) {
    p {
      padding: 0 5vw;
      width: auto;
    }

    h1 {
      margin-top: 0;
    }

    button {
      width: auto;
    }
  }
`;
