import { Cartola, Title, Paragraph } from "../../components/Templates/texts";
import { ButtonPattern } from "../../components";

import { Container, BoxText } from "./styles";

function Block01() {
  return (
    <Container id="inicio">
      <BoxText>
        <Cartola>Sua porta de entrada para criptoativos</Cartola>
        <Title>Capitalize na tendência e negocie com confiança</Title>
        <Paragraph>
          Facilite a compra, a venda e a troca das maiores moedas digitais e
          tokens por meio da Bra.ex. Exchange projetada para quem deseja se
          juntar ao avanço da tecnologia em blockchain.
        </Paragraph>
        <ButtonPattern anchor="/#contato">Junte-se a Bra.ex</ButtonPattern>
      </BoxText>
      <img
        src="/image-block1-min.png"
        className="desktop"
        width="723"
        height="600"
        alt="Mulher usando o celular"
      />
      <img
        src="/image-block1-mobile-min.png"
        className="mobile"
        width="345"
        height="345"
        alt="Mulher usando o celular"
      />
    </Container>
  );
}

export default Block01;
