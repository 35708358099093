import { Container, BoxText } from "./styles";

import { ButtonPattern } from "../../components";
import { Cartola, Title, Paragraph } from "../../components/Templates/texts";

function Block06() {
  return (
    <Container>
      <img
        src="/block06-decoration.svg"
        className="decoration"
        width="413"
        height="743"
        loading="lazy"
        alt=""
      />
      <BoxText>
        <Cartola>Saque facilmente</Cartola>
        <Title weight="600" size="32px" height="36px">
          Use seu dinheiro como quiser. Sem complicações, saque com alguns
          cliques.
        </Title>
        <Paragraph>
          Compre, venda ou saque criptomoedas instantanea e rapidamente usando
          transferências bancárias, cartão de crédito, débito ou PIX.
        </Paragraph>
        <ButtonPattern anchor="https://auth.2ndmarket.com.br/cadastro">
          Transacione agora
        </ButtonPattern>
      </BoxText>
      <img
        src="/image-block6-min.png"
        alt="Homem usando um notebook"
        className="desktop"
        loading="lazy"
        height="600"
        width="600"
      />
      <img
        src="/image-block6-mobile-min.png"
        alt="Homem usando um notebook"
        className="mobile"
        loading="lazy"
        height="345"
        width="345"
      />
    </Container>
  );
}

export default Block06;
