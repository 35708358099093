export const Check = (props) => {

    return <>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.31226 8.54687L7.46851 10.7031L11.0623 6.39062" stroke={ props.white ? '#fff' : '#7d30e0' } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.1875 15.375C12.157 15.375 15.375 12.157 15.375 8.1875C15.375 4.21795 12.157 1 8.1875 1C4.21795 1 1 4.21795 1 8.1875C1 12.157 4.21795 15.375 8.1875 15.375Z" stroke={ props.white ? '#fff' : '#7d30e0' } strokeWidth="1.5"/>
        </svg>
        <style jsx>{`
            svg {

                min-width: 17px;
            }
        `}</style>
    </>
}