import { Container, BoxText, BoxAdditional } from "./styles";

import { ButtonPattern } from "../../components";
import {
  Cartola,
  Title,
  Paragraph,
  Additional,
} from "../../components/Templates/texts";

function Block03() {
  return (
    <Container id="como-funciona">
      <img
        src="/image-block3-min.png"
        className="desktop"
        width="600"
        height="600"
        alt="Transferência por celular"
      />
      <img
        src="/image-block3-mobile-min.png"
        className="mobile"
        width="345"
        height="391"
        alt="Transferência por celular"
      />
      <BoxText>
        <Cartola>Comece a negociar criptografia como quiser</Cartola>
        <Title>
          Leve seus investimentos em criptografia para o próximo nível
        </Title>
        <Paragraph>
          Torne-se um proprietário de criptografia em minutos. Compre, venda,
          troque e saque ativos digitais e/ou criptomoedas.
        </Paragraph>
        <BoxAdditional>
          <div>
            <img
              src="/check-icon.svg"
              width="14"
              height="14"
              loading="lazy"
              alt=""
            />
            <Additional>
              Compre diretamente com seu cartão de crédito ou débito
            </Additional>
          </div>
          <div>
            <img
              src="/check-icon.svg"
              width="14"
              height="14"
              loading="lazy"
              alt=""
            />
            <Additional>
              Acompanhe toda a sua criptografia e o desempenho do seu portfólio
            </Additional>
          </div>
          <div>
            <img
              src="/check-icon.svg"
              width="14"
              height="14"
              loading="lazy"
              alt=""
            />
            <Additional>
              Retire seu dinheiro facilmente sem burocracias
            </Additional>
          </div>
        </BoxAdditional>
        <ButtonPattern anchor="/#contato">Abra sua conta</ButtonPattern>
      </BoxText>
    </Container>
  );
}

export default Block03;
