import React, { useState } from "react";

import {
  Container,
  BoxText,
  BoxAdditional,
  BoxForm,
  ContainerSelect,
  ContainerButton,
} from "./styles";
import {
  Cartola,
  Title,
  Additional,
  Paragraph,
  Strong,
} from "../../components/Templates/texts";

import { ButtonPattern, BaseInput, PhoneInput } from "../../components";
import { Api } from "../../api/api";
import { Check } from "../../components/icons";

function Block08() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [isMessageSent, setIsMessageSent] = useState(false);

  const submit = function (event) {
    event.preventDefault();
    setIsMessageSent(false);

    Api.submitContact({
      name: name,
      email: email,
      phone: phone,
      subject: event.target.querySelector('[name="subject"]').value,
      message: message,
    }).then(function (response) {
      setIsMessageSent(true);
    });

    return false;
  };

  return (
    <Container>
      <div id="contato">
        <BoxText>
          <Cartola>Cadastre-se</Cartola>
          <Title>Oferecemos a melhor experiência em ativos digitais</Title>
          <BoxAdditional>
            <img
              src="/email-icon.svg"
              loading="lazy"
              width="17"
              height="15"
              alt=""
            />
            <Additional>contato@braexmarket.com.br</Additional>
          </BoxAdditional>
        </BoxText>
        <BoxForm name="braexLead" onSubmit={submit}>
          {isMessageSent && (
            <Paragraph style={{ display: "flex", alignItems: "center" }}>
              <Check />
              <Strong marginLeft="10px">Mensagem enviada!</Strong>
            </Paragraph>
          )}

          <BaseInput
            value={name}
            name="name"
            type="text"
            placeholder="Nome"
            changeValue={setName}
          />
          <BaseInput
            value={email}
            type="email"
            name="email"
            placeholder="Email"
            changeValue={setEmail}
          />
          <ContainerSelect>
            <PhoneInput
              type="phone"
              value={phone}
              name="phone"
              placeholder="Telefone"
              changeValue={setPhone}
            />
            <select
              name="subject"
              required
              value={subject}
              onChange={e => setSubject(e.target.value)}>
              <option value="Pré-cadastro">Pŕe-cadastro</option>
              <option value="duvidas">Dúvidas / Suporte</option>
            </select>
          </ContainerSelect>
          <textarea
            name="message"
            placeholder="Mensagem"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <ContainerButton>
            <ButtonPattern>Enviar</ButtonPattern>
          </ContainerButton>
        </BoxForm>
      </div>
      <img src="/register-squares.svg" alt="" className="detail" />
    </Container>
  );
}

export default Block08;
