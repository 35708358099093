import styled from "styled-components";

export const Container = styled.div`
	display: block;
	justify-content: space-between;
	padding: 0 6vw;
  padding-top: 70px;

	@media only screen and (max-width: 1200px) {
		margin: 53px 2vw;
		display: flex;
		flex-direction: column;
	}
`;

export const BoxHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	.decoration {
		position: absolute;
		top: -150px;
		right: 0;
	}

	.decorationMobile {
		display: none;
	}

	@media only screen and (max-width: 1200px) {
		display: flex;
		width: 100%;
		justify-content: flex-end;

		.decoration {
			display: none;
		}

		.decorationMobile {
			display: none;
			margin-right: -20px;
		}
	}
`;

export const BoxTitle = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 70%;
	margin-bottom: 75px;

	@media only screen and (max-width: 1200px) {
		max-width: 100%;
		margin: 0 0 32px;

		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 36px;
		}
	}
`;

export const BoxCards = styled.div`
	display: block;
	justify-content: center;

	.swiper-container {
		width: 480px;
	}

	@media screen and (min-width: 640px) {
		display: block;
		justify-content: center;

		.swiper-container {
			width: 640px;
		}
	}

	@media screen and (min-width: 768px) {
		display: block;
		justify-content: center;

		.swiper-container {
			width: 768px;
		}
	}

	@media screen and (min-width: 1200px) {
		.swiper-container {
			width: 1200px;
		}
	}

	.mySwiper {
		.swiper-wrapper {
			.swiper-slide {
				min-height: 240px;
			}
		}

		@media only screen and (max-width: 1200px) {
			.swiper-wrapper {
			}
		}
	}
`;

export const ContainerButtons = styled.div`
	display: flex;
	margin-top: 40px;

	@media only screen and (max-width: 1200px) {
		margin-left: 0;
	}
`;

export const Button = styled.button`
	display: flex;
	background-color: #1e2c39;
	padding: 13px;
	cursor: pointer;
	margin-right: 5px;
	border-radius: 2px;
	border: none;
	border: 1px solid transparent;
	transition: 0.5s;

	:hover {
		transition: 0.5s;
		border: 1px solid #7d30e0;
	}
`;
