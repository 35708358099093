import styled from "styled-components";

export const Container = styled.div`
  margin: 0 12vw;

  @media only screen and (max-width: 1200px) {
    margin: 0 4vw;
  }

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fafafa;
  }

  span {
    color: #7d30e0;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #FAFAFA;

  strong {
    color: #B794F6;
    font-weight: 600;
  }
`

export const BoxActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 78px 8vw 0 8vw;

  @media only screen and (max-width: 1200px) {
    margin: 0 8vw 30px 8vw;
    flex-wrap: wrap;
    gap: 22px;
  }
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8D9BAD;

    @media only screen and (max-width: 1200px) {
      margin-bottom: 35px;
    }
  }
`;

export const ActionImage = styled.div`
  width: 60px;
  height: 60px;
  background-color: #1e2c39;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 11px;
`;
